import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import Layout from "../components/Layout";
import { Link as RouterLink, PageProps } from "gatsby";
import { MetaData } from "../components/common/meta";

import Arrow from "../images/arrow-right.inline.svg";
import bricks from "../images/bricks.svg";
import mesh from "../images/cyclone/cyclone.svg";
import side from "../images/cyclone/side.svg";
import article from "../images/article.svg";

const cyclone = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData
        title="Cyclone"
        description="Discover how Cyclone married Jump Crypto’s expertise in hardware, FPGA design, and zero-knowledge proofs to enable the development of highly-scalable distributed systems and privacy-enabled applications."
        location={location}
        type="website"
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: "-140px",
            top: "-240px",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 963,
            mx: "auto",
            pt: { xs: 12, md: 22 },
            mb: { xs: 6, md: 12 },
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 3 }} variant="h1">
            Pushing{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              boundaries
            </Box>
          </Typography>
          <Typography sx={{ maxWidth: 500, mx: "auto" }}>
            Our world-class research team continues to explore the horizon of
            zero-knowledge, tokenomics, security, and more.
          </Typography>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: "relative",
          pt: { md: 12 },
          mb: { xs: 5, md: 12 },
          zIndex: 2,
        }}
      >
        <Box
          component="img"
          src={mesh}
          sx={{
            width: "100%",
            transform: "scale(1.12)",
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            objectFit: "contain",
            objectPosition: "center right",
            opacity: 0.5,
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 775,
            background: "#051535",
            border: "1px solid #4B608D",
            p: { xs: 3, md: 7 },
            ml: { xs: "auto", lg: 5 },
            mr: { xs: "auto", lg: "initial" },
            mt: { lg: -13 },
            zIndex: 2,
          }}
        >
          <Typography variant="h2" sx={{ mb: 5, mt: { xs: 3, md: 0 } }}>
            Cyclone
          </Typography>
          <Typography>
            Zero-knowledge (ZK) proofs are quickly becoming a core primitive for
            blockchains as a means for enabling the development of
            highly-scalable distributed systems and privacy-enabled
            applications. Hence, low-latency proof generation is critical to the
            success of ZKs. Discover how we married our expertise in hardware
            and FPGA design with our experience in crypto to help accelerate
            core components of these proof systems. The code is open-source, and
            we're excited to be contributing to the future of zero-knowledge.
          </Typography>
          <Button
            component={RouterLink}
            to="/accelerating-msm"
            variant="outlined"
            endIcon={<Arrow />}
            sx={{ mt: { xs: 2, md: 3 }, mr: 2 }}
          >
            .Read the announcement
          </Button>
        </Box>
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 775,
            background: "#051535",
            border: "1px solid #4B608D",
            p: { xs: 3, md: 7 },
            ml: { xs: "auto", lg: 5 },
            mr: { xs: "auto", lg: "initial" },
            mt: 3,
            zIndex: 2,
          }}
        >
          <Typography variant="h2" sx={{ mb: 5, mt: { xs: 3, md: 0 } }}>
            Research
          </Typography>
          <Typography>
            We’re actively working with the research community to drive various
            efforts in the Zero Knowledge space forward. Discover some of our
            most recent work below:
          </Typography>
          <Box
            component="div"
            sx={{
              mt: 4,
              position: "relative",
              width: { xs: "100%", md: "815px" },
              ml: { xs: "auto", lg: 8 },
              mr: { xs: "auto", lg: "initial" },
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#091A3D",
                border: "1px solid #4B608D",
                p: 3.2,
                mb: 2.4,
                textAlign: { xs: "center", md: "left" },
                flexDirection: { xs: "column", md: "row" },
                "& h2": {
                  fontSize: { lg: 40 },
                },
              }}
            >
              <Box component="img" src={article} alt="Aptos World Tour" />

              <Typography
                variant="h5"
                sx={{ flex: 1, px: 2.4, my: { xs: 2.4, md: 0 } }}
              >
                FPGA Acceleration of Multi-Scalar Multiplication:
                <Box component="span" sx={{ color: "#1CE7C2" }}>
                  {"  CycloneMSM"}
                </Box>
              </Typography>
              <Button
                component={RouterLink}
                to="/research/fpga-acceleration-of-multi-scalar-multiplication-cyclonemsm/"
                variant="outlined"
                endIcon={<Arrow />}
                sx={{ minWidth: "155px" }}
              >
                .Learn more
              </Button>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#091A3D",
                border: "1px solid #4B608D",
                p: 3.2,
                mb: 2.4,
                textAlign: { xs: "center", md: "left" },
                flexDirection: { xs: "column", md: "row" },
                "& h2": {
                  fontSize: { lg: 40 },
                },
              }}
            >
              <Box component="img" src={article} alt="Aptos World Tour" />

              <Typography
                variant="h5"
                sx={{ flex: 1, px: 2.4, my: { xs: 2.4, md: 0 } }}
              >
                <Box component="span" sx={{ color: "#1CE7C2" }}>
                  {" CycloneNTT: "}
                </Box>
                An NTT/FFT Architecture Using Quasi-Streaming of Large Datasets
                on DDR- and HBM-based FPGA Platforms
              </Typography>
              <Button
                component={RouterLink}
                to="/research/cyclonentt-an-ntt-fft-architecture-using-quasi-streaming-of-large-datasets-on-ddr-and-hbm-based-fpga-platforms/"
                variant="outlined"
                endIcon={<Arrow />}
                sx={{ minWidth: "155px" }}
              >
                .Learn more
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", xl: "block" },
          position: "absolute",
          left: "54px",
          top: "260px",
        }}
        component="img"
        src={side}
        alt=""
      />
    </Layout>
  );
};

export default cyclone;
